<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginComplete",
  created() {
    this.$router.push("/dashboard");
  }
};
</script>
